<template>
    <div class="appraise">
        <div class="tilte">
            <div></div>
            <span>{{$t('button.shenqingshouhuo')}}</span>
        </div>
        <div class="pinfen">
            <div class="title">{{$t('mine.elses.cause')}}</div>
            <div class="title">
                <div class="radio-group">
                    <div class="radio" @click="radio= index" :class="radio == index ? 'radioActive':''" v-for="(item,index) of afterSaleList" :key="index">
                        <div>
                            <div></div>
                        </div>
                        <span>{{item}}</span>
                    </div>
                </div>
            </div>
            <div class="title">{{$t('mine.elses.miaoshu')}}</div>
            <textarea class="textarea" v-model="textarea" :placeholder="$t('mine.elses.jianshu')"></textarea>
            <div class="addbtn" @click="confirm">{{$t('button.tijiao')}}</div>
        </div>
    </div>
</template>

<script>
 import {refundAfterOrder} from '@/api/main.js'
export default {
    data() {
        return {
            value:1,
            textarea:'',
            radio:0,
            orderId:'',
            afterSaleList:[]
        }
    },
    mounted() {
        this.orderId = this.$route.query.id;
        console.log(this.$router);
        if(this.isChinese()){
            this.afterSaleList = ['不喜欢','商品描述与实物不符','商家发错货','包装/商品破损/污渍','其他'];
        }else{
            this.afterSaleList = ['Dislike goods','The product description is inconsistent with the real object','Merchant sends wrong goods','There is a problem with the product','other'];
        }
    },
    methods: {
        confirm(){

            refundAfterOrder({
                orderId:this.orderId,
                afterReason:this.afterSaleList[this.radio],
                afterDescription:this.textarea
            }).then(res=>{
                if(res.code==0){
                    if(this.isChinese()){
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                    }else{
                        this.$message({
                            message: 'Submit successfully',
                            type: 'success'
                        });
                    }
                    this.$router.back();
                }else{
                    this.showMessage(res)
                }
            })

        }
    }
}
</script>
<style lang="scss">
.appraise {
    width: 880px;
   
    .tilte {

        display: flex;
        align-items: center;
        padding: 9px 0px;
        padding-top: 0px;

        div {
            width: 2px;
            height: 24px;
            background: #333333;
            margin-right: 10px;
        }

        span {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
    }

    .pinfen {
        overflow: hidden;
        transition: height 1s ease;
        margin-top: 20px;
        .h3 {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            padding: 20px 0px;
            padding-bottom: 0px;
            border-top: 1px solid #ECECEC;
        }

        .title {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 25px;
            padding: 20px 0px;
            
            .radio-group{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 800px;
                
                .radio{
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-right: 40px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    >div{
                        border-radius: 50%;
                        width: 14px;
                        height: 14px;
                        border: 1px solid #999999;
                        margin-right: 10px;
                        padding: 2px;
                        box-sizing: border-box;
                        div{
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                        }
                    }
                }
                .radioActive{
                    color: #9999;
                    >div{
                        border-radius: 50%;
                        width: 14px;
                        height: 14px;
                        border: 1px solid #999999;
                        margin-right: 10px;
                        padding: 2px;
                        box-sizing: border-box;
                        div{
                            width: 8px;
                            height: 8px;
                            background-color: #000;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }

        .textarea {
            padding: 10px;
            width: 100%;
            resize: none;
            
            height: 100px;
            margin: 20px 0px;
            margin-top: 0px;
            box-sizing: border-box;
        }

        .addbtn {
  margin-right: 20px;
  margin-top: 20px;
  width: 148px;
  height: 50px;
  border: 1.5px solid transparent;
  border-radius: 0;
  white-space: nowrap;
  transition: all 0.3s ease;
  border: 2px solid #000;
  text-align: center;
  padding: 15px 0px;

  img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
}

.addbtn:hover {
  background: #000;
  color: #fff;
  cursor: pointer;
}
    }
}
</style>